.payerInfoTable td, .payerInfoTable th {
  padding: 14px;
  white-space: nowrap;
}
.payerInfoTable td:first-child {
  padding-left: 40px;
}
.payerInfoTable tr:nth-child(odd) {
  background-color: #f5faf5;
}
.payerInfoTable th {
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
}
.payerInfoTable td {
  color: #212220;
  font-size: 18px;
  font-weight: 400;
}
h4.payer-title {
  color: #212220;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 32px;
  font-weight: 500;
  margin: 0px;
  padding: 1.5rem 0rem;
}
p.payer-name{
  color: #212220;
  font-size: 32px;
  font-weight: 500;
}
.payer-hios {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  border-right: 1px solid #DADADA;
}
p.hios-text {
  color: #212220;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1rem;
  white-space: nowrap;
}
p.hios-value {
  color: #212220;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
}
h2.table-title {
  color: #02363D;
  font-size: 32px;
  font-weight: 500;
  line-height: 53.2px;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media(max-width:1199px){
  .payer-hios{
    margin-right: 1rem;
  }
}
@media(max-width:767px){
  p.payer-name{
    font-size: 20px;
  }
}
@media(max-width:599px){
  .payer-hios{
    border-right:0px;
    margin-right:0px;
  }
  .avatar-text {
    display: block !important;
    text-align: center;
  }
  .avatar-center{
    margin: auto;
  }
}

.view-more {
  color: #45a736;
  text-decoration: none;
}