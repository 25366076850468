.input-container__search {
  margin: auto;
  height: 60px;
  margin: 20px auto 28px auto;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 40px;
  padding: 0px 0px 0px 10px;
  background-color: white;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}

.inputField {
  width: 100%;
}

.select-procedure {
  width: 40%;
}

.select-procedure fieldset {
  border: none;
}
.search-btn {
  color: white;
  background-color: black;
  font-size: 25px;
  height: 100%;
  padding: 0px 25px;
  border: none;
  border-radius: 0 40px 40px 0;
  cursor: pointer;
}

.css-1r0qf8a-JoyAutocomplete-root {
  --Input-focusedHighlight: none !important;
}

.css-1qa52vo-JoyAutocomplete-root:not([data-skip-inverted-colors]) {
  --Input-focusedHighlight: none !important;
}

.input-container__search
  .css-1x51dt5-MuiInputBase-input-MuiInput-input::placeholder {
  color: rgb(42, 71, 165);
}
.select-state {
  width: 40%;
}

.select-state fieldset {
  border: none;
}

#banner-input::placeholder {
  color: #212220;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  opacity: 1; /* Firefox */
}

#banner-input::-ms-input-placeholder { /* Edge 12 -18 */
  color: #212220;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
input#banner-input {
  padding-left: 0.3rem;
}
.input-container__search button.search-ppp-pages{
    width: 201px;
    border-radius: 0px 50px 50px 0px;
    background: #212220 !important;
    font-weight:600;
    color: #fff;
    height: 100%;
    font-size: 23px;
    text-transform: none;
}
.select-state svg , .location-auto button.MuiAutocomplete-popupIndicator svg{
  font-size: 0px;
  background-image: url('../Static/droparrow-down.svg');
  width: 13px;
  height: 9px;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  position: relative;
  top: 1px;
}
@media (min-width:900px) and (max-width:1100px){
  .banner-search-inner {
    top: 79%;
    transform: translateY(-50%);
  }
  .banner-search {
    height: 65px !important;
    width: 800px !important;
  }
  .banner-search .MuiInputBase-root {
    width: 337px;
  }
  .banner-search button {
    width: 52%;
  }
}
@media (min-width:768px) and (max-width:899px) {
  .banner-search {
    width: 100% !important;
    justify-content: space-between;
  }
  .banner-search .MuiInputBase-root {
    width: auto;
  }
  .banner-search-inner {
    width: 95%;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width:767px){
  body .banner-search , .input-container__search{
    width: 89%;
    height: auto;
    flex-direction: column;
    padding: 1rem;
  }
  .banner-search .MuiInputBase-root , .input-container__search .MuiInputBase-root{
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;
  }
  .banner-search-inner {
    width: 100%;
    position: relative !important;
    bottom: 0rem !important;
    margin-bottom: 2rem;
  }
  .banner-search hr , .input-container__search hr {
    display: none;
  }
  .banner-search button , .input-container__search button.search-ppp-pages{
    width: 100%;
    border-radius: 50px;
    font-size: 18px;
  }
  .input-container__search{
    width: auto;
  }
  .browse-handle__nav {
    flex-direction: column;
  }
  .nav-tabs {
    margin-top: 1rem;
  }
  .dropdown-list{
    width: 100% !important;
  }
  .locationSearch {
      width: 100% !important;
      z-index: 1;
  }
}
@media (max-width:367px){
  .nav-tabs {
    width: 300px;
    /* overflow-x: scroll !important; */
  }
  .MuiTabs-scroller{
    overflow-x: scroll !important;
  }
  /* width */
  .MuiTabs-scroller::-webkit-scrollbar {
    width: 0px;
  }
  /* Handle */
  .MuiTabs-scroller::-webkit-scrollbar-thumb {
    background: transparent; 
    border-radius: 10px;
  }
  .browse-handle__nav {
    border: 0px !important;
  }
  .MuiTabs-flexContainer {
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
  }
}

@media (min-width:768px) and (max-width:1024px){
  .pro-search .MuiInputBase-root{
      width: 300px !important;
  }
}

@media (max-width:767px){
  .pro-button{
    width: 100% !important;
    border-radius: 30px !important;
  }
  .locationProcSearch{
    width: 100% !important;
    top: 58% !important;
    left: 22% !important;
  }
  .provider-search{
    width: 100%;
    position: relative;
  }
  .provider-search ul{
    z-index: 1;
    top: 40px;
    width: 100%;
    left: 0px;
    margin: 0px;
  }
  .payer-search{
    width: 100%;
    position: relative;
  }
  .payer-search ul{
    z-index: 1;
    top: 40px;
    width: 100%;
    left: 0px;
    margin: 0px;
  }
}