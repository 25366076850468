.about-div {
    margin-bottom: 30px;
}

.about-our-story{
    padding-left: 30px
}

@media (max-width: 1500px){
    .aboutUsImg{
        width: 100% !important;
    }
}

@media (max-width: 1199px){
    .about-our-story{
        padding-left: 0px
    }
}