.alphabet-search button {
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-bottom: 8px;
}
.alphabet-search button.active {
  border-radius: 5px;
  background: #E3F2E1;
  color: #02363D;
  font-weight: 600 !important;
}
.search-result__content {
  background-color: #f5f5f5 !important;
  margin-top: 20px;
}

.search-result__content .card-listing__bottomHandler {
  border-radius: 10px 10px 0px 0px;
}
.alphabet-search button:first-child {
  margin-right: 5px;
}