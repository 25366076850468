.ads-container {
  margin: auto;
  background-color: #45A736;
  height: 322px;
  border-radius: 15px;
  display: flex;
}
.ads-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  padding-left: 43rem;
  height: 100%;
  padding-right: 3rem;
}
.ads-subtext1 {
  margin: 0px;
  padding: 0 20px;
  color: #212220;
  font-size: 25px;
  font-weight: 500;
  line-height: 25.6px;
  margin-bottom: 1rem;
}
.ads-subtext2 {
  margin: 0px;
  padding: 0 20px;
  color: #FFF;
  font-size: 43px;
  font-weight: 600;
  line-height: 50px;
}
.home-footer{
  position: absolute;
  left: 0px;
  right: 0px;
  transform: translateY(-60%);
}
.footer-main{
  padding-top: 11rem;
}
@media (min-width:900px) and (max-width:1200px){
  .ads-subtext2{
    font-size: 18px !important ;
    line-height: normal !important;
  }
}
@media (max-width:992px){
  .ads-container {
    height: auto;
    display: block;
    text-align: center;
    padding: 2rem;
  }
  .ads-container img {
    position: relative !important;
    top: 0px !important;
    width: 300px;
    margin: auto;
  }
  .ads-text {
    position: relative;
    padding: 0rem;
    margin-top: 2rem;
  }
}
@media (max-width:899px){
  .ads-container {
    height: auto;
    display: block;
    text-align: center;
    padding: 2rem;
  }
  .ads-container img {
    position: relative !important;
    top: 0px !important;
    width: 300px;
    margin: auto;
  }
  .ads-text {
    position: relative;
    padding: 0rem;
    margin-top: 2rem;
  }
  .home-footer {
    position: relative;
  }
  .home-footer {
    position: relative;
    transform: none;
    margin-bottom: 6rem;
  }
  .home-page {
    padding-bottom: 0px !important;
  }
  .footer-main {
    padding-top: 0px !important;
  }
  .ads-subtext2{
    font-size: 22px !important ;
    line-height: normal !important;
  }
}
@media (max-width:500px){
  .ads-container img {
    width: 100%;
  }
  .ads-subtext2 {
    padding: 0px 10px;
    font-size: 24px;
    line-height: normal;
  }
  .ads-subtext1 {
    padding: 0 10px;
    font-size: 20px;
  }
}