.headernew {
    color: #fff;
    transition: background-color 0.3s ease;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
.headernew .bar-img {
    display: none;
}
.headernew.sticky .bar-img {
    display: flex;
    /* position: fixed; */
    /* left: 4rem; */
}
ul.linear-menu {
    margin-top: 1rem;
}
ul.linear-menu li.MuiListItem-root {
    width: 100%;
    border-bottom: 1px solid #fff;
    padding: 10px 0px;
}
div.toggle-div {
    /* display: none; */
    position: absolute;
    top: 100%;
    left: -320px;
    background-color: rgb(69, 167, 54);
    -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,0.25);
    box-shadow: 1px 2px 3px rgba(0,0,0,0.25);
    overflow: hidden;
    z-index: 499;
    width: 300px;
    height: 100vh;
    left: -400px;
    opacity: 0;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
}
div.toggle-div.active{
    opacity: 1;
    left: 0;
}
.menicon-change.active{
    background-image: url("../Static/close-bold-svg.svg");
}
.menicon-change.active path {
    display: none;
}
.basic-menu .MuiPaper-root{
    width: 145px;
}
.basic-menu li.MuiListItem-root{
    justify-content: center;
    text-transform: capitalize;
    padding: 0px 15px;
    border-bottom: 1px solid #45A736;
}
.basic-menu li.MuiListItem-root:last-child {
    border-bottom: 0px;
}
.basic-menu li.MuiListItem-root button.MuiButtonBase-root {
    color: #02363D;
    font-size: 19px;
    text-transform: capitalize;
}