/* @font-face {
  font-family: "poppins";
  src: url("Assets/Font/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "poppins semi";
  src: url("Assets/Font/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "poppins thin";
  src: url("Assets/Font/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "poppins light";
  src: url("Assets/Font/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "poppins regular";
  src: url("Assets/Font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "inter regular";
  src: url("Assets/Font/Inter-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "-apple-system", " BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
    monospace;
} */

.modal-select {
  background: #f4fbf5 !important;
  border-radius: 5px !important;
}
.modal-select:hover {
  border: 1px solid #DADADA !important;
}
ul[aria-labelledby="select-ul"] {
  height: 250px;
}
#modal-select-drop {
  word-break: break-word;
  white-space: normal;
  width: 290px;
}
.city-drop {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #cfe6d3;
  border-radius: 5px;
  position: absolute !important;
  background: #fff;
  z-index: 9;
  width: 100%;
}