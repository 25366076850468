.priceTable {
  margin: 20px 40px;
  width: 90%;
  border-collapse: collapse;
}
.priceTable td {
  border: 2px solid #f1f1f1;
  color: #02363d;
  text-align: left;
  padding-left: 15px;
}
.userData__handle {
  margin: 0px 0px 0px !important;
  margin-left: -16px !important;
}
.userData__handle input {
  border: none;
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
}
.MuiInputBase-root.select-userdata{
  padding: 0px;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  width: 100%;
}
.MuiInputBase-root.select-userdata input{
  height: auto;
}
.MuiInputBase-root.select-userdata fieldset , .userData__handle fieldset{
  border-color: transparent !important;
  top: 0px;
}
p.report-cash {
  color: #141616;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}