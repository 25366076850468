@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* .footer-image{
  width:550px;
  height:120%;
} */

*{
  font-family: 'Poppins', sans-serif !important;
}
.MuiIconButton-root:hover{
  background-color: transparent !important;
}
.MuiTouchRipple-root{
  display: none;
}
body {
  margin: 0px;
}
@media(min-width:1200px){
  body .container-common {
    max-width: 1550px;
  }
}
@media(min-width:1200px) and (max-width:1440px){
  body .container-common .ads-container .ads-text .ads-subtext2 {
    font-size: 28px !important;
    line-height: normal !important;
  }
}
/* header style start */
.header-menu button {
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  margin-right: 2rem;
  padding-right: 5px;
  padding-left: 8px;
}
.header-menu button:last-child {
  margin-right: 0rem;
}
@media(max-width:599px){
  .header-menu {
    text-align: right;
    margin-top: 1rem;
  }
  .main-top {
    margin-top: 150px !important;
  }
}
/* header style end */

/* banner style start */
.your-path {
  color: #FFF;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0px;
}
p.path-right span{
  font-family: 'Montserrat', sans-serif !important;
}
.Commited {
  color: #2A3D53;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}
.banner-seaction div {
  width: 50%;
}
img.banner-img{
  /* height: 100%; */
  width:590px;
  object-fit: contain;
}
input#banner-input {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block !important;
  padding-right: 1rem;
}
@media (min-width:1101px){
  input#banner-input {
    width: 70% !important;
  }
}
@media (min-width:1200px) and (max-width:1370px){
  .your-path {
    font-size: 35px;
  }
  .Commited{
    font-size: 15px;
  }
}
@media (min-width:900px) and (max-width:1100px){
  img.banner-img {
    width: 590px;
    /* width: 300px; */
    object-fit: contain;
  }
}
@media (min-width:900px) and (max-width:1199px){
  .your-path {
    font-size: 35px;
  }
  .Commited{
    font-size: 14px;
  }
}
@media (max-width:899px){
  .your-path, .Commited {
    text-align: center !important;
    margin-top: 0px;
  }
  .banner-seaction div {
    width: 100%;
    text-align: center;
  }
}
@media (max-width:700px){
  img.banner-img {
    width: 100%;
  }
  .your-path {
    font-size: 40px;
  }
}
/* banner style end */

/* procedure style start */
.browse-handle__nav h3 {
  color: #212220;
  font-size: 32px;
  font-weight: 500;
  line-height: 34px;
}
ul.procedure-list{
  padding-top: 8px;
  padding-bottom: 8px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 8px;
  max-height: 141px;
  overflow-y: auto;
  color: #000;
  width: 100%;
  z-index: 9;
}
.prod-search ul.procedure-list{
  top: 152%;
}
.dropdown-list.prod-search .MuiInputBase-root {
  padding-right: 3rem;
}

/* width */
/* .procedure-list::-webkit-scrollbar {
  width: 8px;
} */
/* Track */
/* .procedure-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 8px;
}  */
/* Handle */
/* .procedure-list::-webkit-scrollbar-thumb {
  /* background: #45A736;  */
  /*background:grey;
  border-radius: 10px;
} */
.card-listing__topHandler {
  border-radius: 0px 0px 10px 10px !important;
}
.all-inner {
  border-radius: 5px;
  border: 1px solid #DADADA;
  background: #FFF;
  box-shadow: none;
  height: 100%;
  cursor: pointer;
  color: #212220;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 3px 0px;
}
.all-inner div{
  padding: 0px 10px;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.all-inner:hover {
  background-color: #E3F2E1;
  color: #45A736;
}
ul.MuiPagination-ul button.MuiButtonBase-root.MuiPaginationItem-root {
  border-radius: 20px;
  background: #F5F5F5;
  width: 40px;
  height: 40px;
  color: #02363D;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
ul.MuiPagination-ul button.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background: #45A736;
  color: #fff;
}
@media (max-width:600px){
  ul.MuiPagination-ul button.MuiButtonBase-root.MuiPaginationItem-root{
    width: 30px;
    height: 32px;
    font-size: 12px;
    margin: 0 2px;
  }
}
/* procedure style end */
.coming-soon{
  text-align: center;
  margin: 50px 50px;
}
@media(max-width:600px){
  .coming-soon img{
    width: 100%;
  }
}
.MuiAutocomplete-root.location-auto{
  border: 0px;
  background: #fff;
  box-shadow: none;
  --Input-focusedHighlight: 0 !important;
  padding: 0;
}
.filter-text{
  margin: 0px;
  color: #212220;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.MuiInputBase-root.filter-input{
  width: 100%;
  height: 45px;
  background-color: #fff;
  border-radius: 10px;
  color: #212220;
  font-size: 14px;
  font-weight: 400;
  margin: 7px 0px;
}
.MuiInputBase-root.filter-input fieldset {
  border: 0px;
}
.MuiInputBase-root.filter-input svg{
  font-size: 0px;
  background-image: url('./Assets/Static/filterarrow.svg');
  width: 13px;
  height: 9px;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  position: relative;
  top: 1px;
  right: 15px;
}
.location-auto button.MuiAutocomplete-clearIndicator{
  padding: 0px;
  margin: 0px;
}
.results-text{
  color: #212220;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin: 2rem 0rem;
  padding-right: 2rem;
}
.MuiInputBase-root.sort-pricing{
  border-radius: 11px;
  border: 1px solid #DADADA;
  background: rgba(69, 167, 54, 0.05);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  color: #212220;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.MuiInputBase-root.sort-pricing .MuiSelect-select{
  padding: 12px 13.384px 12px 16.384px;
}
.sort-pricing.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
  border-width: 0px !important;
}
.MuiInputBase-root.sort-pricing:hover fieldset {
  border-color: #DADADA !important;
}
.sort-pricing svg{
  font-size: 0px;
  background-image: url('./Assets/Static/filterarrow.svg');
  width: 13px;
  height: 9px;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  position: relative;
  top: 1px;
  right: 15px;
}
.MuiList-root {
  padding: 0px !important;
}
.MuiList-root[aria-labelledby="sort-pricing"] li.MuiButtonBase-root.MuiMenuItem-root {
  border-bottom: 1px solid rgba(33, 34, 32, 0.30);
  margin: 0.3rem 0.5rem;
  padding: 8px;
}
.MuiList-root[aria-labelledby="sort-pricing"] li.MuiButtonBase-root.MuiMenuItem-root:last-child {
  border-bottom: 0px;
}
.MuiList-root[aria-labelledby="all-state"] {
  height: 200px;
}
p.hcps-text {
  color: #212220;
  font-size: 14px;
  font-weight: 400;
  line-height: 31.683px;
  margin: 0px;
}
p.service-hcpcs {
  color: #45A736;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}
p.cash-price-value {
  color: #45A736;
  font-size: 20px;
  font-weight: 700;
  line-height: 31.683px;
  margin: 0px;
}
p.cash-price-text {
  color: #858585;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.91px;
  margin: 0px;
}
p.location-name {
  color: #45A736;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.773px;
  margin: 0px 0px 8px 0px;
}
p.loc-address {
  color: #212220;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.773px;
  margin: 2px 0px;
}
.MuiButtonBase-root.loc-view-profile {
  background: #45A736 !important;
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  text-transform: capitalize;
  white-space: nowrap;
}
@media(max-width:899px){
  .results-text{
    font-size: 20px;
  }
}
@media(max-width:767px){
  .MuiAutocomplete-root.location-auto{
    margin-bottom: 1rem;
    width: 100%;
  }
}
@media(max-width:599px){
  .MuiInputBase-root.sort-pricing{
    margin-bottom: 1rem;
  }
  .results-text {
    font-size: 16px;
    margin: 1rem 0rem;
    padding-right: 0rem;
  }
  p.cash-price-text {
    margin-bottom: 1rem;
  }
  .MuiButtonBase-root.loc-view-profile {
    margin-top: 1rem;
  }
}
h2.title-provider {
  color: #212220;
  font-size: 28px;
  font-weight: 600;
  text-decoration-line: underline;
}
.addres-get{
  width: 80px;
  color: #02363D;
  font-size: 20px;
  font-weight: 500;
}
.get-dir{
  text-decoration: underline;
  color: #45A736;
  font-size: 16px;
  font-weight: 500;
}
.addres-get, .get-dir, .location-css-handle p {
  margin-bottom: 1rem !important;
}
h4.provinfo-title{
  color: #212220;
  font-size: 27px;
  font-weight: 500;
}
.MuiChip-root.chip-service {
  width: 100%;
  border-radius: 30px;
  border: 0.633px solid #DADADA;
  background: rgba(255, 255, 255, 0.00);
  padding: 1.5rem;
  color: #858585;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  
  /* white-space: normal; */
}
.chip-service .css-1jzq0dw-MuiChip-label{
  white-space: normal;
}
.MuiChip-root.chip-service:hover {
  color: #45A736; 
  background: rgba(69, 167, 54, 0.15);
  border: 0.633px solid transparent;
}
input.input-service {
  width: 280px;
}
@media(max-width:599px){
  input.input-service {
    width: 100%;
  }
}
p.ray-title {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0.8rem;
}
p.ray-subtitle {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
p.sub-ray {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 0rem;
}
.MuiList-root[aria-labelledby="select-data"] {
  height: 200px;
}
.MuiList-root[aria-labelledby="select-data"] li.MuiButtonBase-root {
  white-space: normal;
  font-size: 14px;
}
@media(min-width:600px){
  .MuiList-root[aria-labelledby="select-data"] {
    width: 280px;
  }
}
.about-us {
  position: absolute;
  width: 201px;
  height: 60px;
  top: 341px;
  left: 580px;
  /* color: #FFFFFF; */
  color: #000;
  font-family: Poppins;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.3px;
}
.contactUs-btn{
  background-color: #45A736;
  width:  148px;
  height: 43.31px;
  top: 1013px;
  left: 318px;
  padding: 10.66px, 0px, 10.66px, 0px;
  border-radius: 8.53px;
  border: 1.07px;
  gap: 8.53px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.aboutUs-para {
  top: 845px;
  left: 318px;
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #858585;
}
.grid-frames {
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.30000001192092896px;
text-align: left;
}
.download-app{
  background-color: #000;
  color: #FFFFFF;
  border-radius: 10px;
}
.MuiDialog-root.provide-popup .MuiPaper-root {
  border-radius: 20px;
}
.map-left{
  padding-left: 2rem;
  height: 100%;
}
.provide-left fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid #DADADA;
}
.provide-left .MuiInputBase-root{
  height: 50px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 15px;
}
.serchh-imge svg{
  background-image: url("./Assets/Static/search-gray.svg");
}
.loc-imge svg{
  background-image: url("./Assets/Static/Location Icon-gray.svg");
}
.loc-imge svg  , .serchh-imge svg {
  background-repeat: no-repeat;
  background-size: contain;
  width: 22px;
}
.loc-imge svg path , .serchh-imge svg path{
  opacity: 0;
}
.serchh-imge input , .loc-imge input{
  padding-left: 15px;
}
.MuiDialog-root.provide-popup .MuiPaper-root .MuiGrid-item {
  padding-left: 0px !important;
}
.photo-camera {
  position: relative;
}
.photo-camera .photo-camera-green {
  position: absolute;
  bottom: -3px;
  right: -4px;
}
.photo-camera-green svg{
  background-image: url("./Assets/Static/green-camera.svg");
  background-size: contain;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}
.photo-camera-green svg path ,.photo-camera-green svg circle{
  opacity: 0;
}
.pofile-popup .MuiPaper-root {
  border-radius: 20px;
  max-width: 400px;
}
.pofile-popup fieldset {
  border-color: transparent;
}
:focus-visible{
  outline: 0px !important;
}
.MuiInputBase-root:hover fieldset.MuiOutlinedInput-notchedOutline{
  border-color: #DADADA !important;
}
.MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
}
img.app-img {
  width: 100%;
}
.MuiDialog-root.signup-page .MuiPaper-root{
  padding: 1rem;
}
.signup-page fieldset.MuiOutlinedInput-notchedOutline , .contact-text fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid #DADADA;
}
.signup-page .MuiInputBase-root , .contact-text .MuiInputBase-root{
  height: 50px;
  border-radius: 6px;
}
.signup-page .MuiFormControl-root , .contact-text .MuiFormControl-root{
  margin-bottom: 16px;
}
.signup-page .MuiInputBase-root input , .contact-text .MuiInputBase-root input {
  height: 30.4px;
}
.contact-text .message-textarea .MuiInputBase-root {
  height: auto;
}
.make-better{
  text-align: center;
  margin-bottom: 0px;
  letter-spacing: 1px;
  font-size: 55px;
  font-weight: 700;
  line-height: normal;
  padding: 0px 16px;
}
.get-touch{
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0px;
}
.testimonials button.MuiButtonBase-root {
  width: 45px;
  height: 64px;
  border: 1px solid #45A736;
  border-radius: 100%;
  margin-left: 1rem;
  color: #45A736;
  background: #ecf7ec;
  cursor: pointer;
}
.testimonials button.MuiButtonBase-root.Mui-disabled{
  border: 1px solid gray;
  color: gray;
  background: #fff;
}
.MuiInputBase-root.cur-loc:before {
  display: none;
}
.MuiInputBase-root.cur-loc {
  border: 1px solid #DADADA;
  padding-left: 15px;
}
.testimonials-outbox {
  height: 100%;
}
@media(max-width:1199px){
  .doctor-img-sec , .doctprofile{
    text-align: center;
  }
  .doctor-img{
    width: auto;
    height: auto;
    object-fit: contain;
    margin: auto;
  }
  .doctprofile img {
    width: auto !important;
  }
}
@media(max-width:899px){
  .map-left{
    padding-left: 0rem;
  }
}
@media(max-width:768px){
  .doctprofile img {
    width: 100% !important;
  }
}
@media(max-width:699px){
  .getapp-img{
    width: 100%;
  }
  .make-better{
    font-size: 35px;
    line-height: normal;
  }
  .get-touch{
    font-size: 25px;
  }
}